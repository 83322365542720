<div class="container-fluid">
    
    
    

   

  

    <!-- =========================================================== -->

    <!-- Small Box (Stat card) -->
    
    <div class="row">
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-info">
          <div class="inner">
            <h4>Bank Balance</h4>

            <p>{{bank}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-check-alt"></i>
          </div>
          <a href="#" class="small-box-footer">
            
          </a>
        </div> -->
        <div class="col-md-3 col-sm-6 col-12">
          <div class="info-box">
            <span class="info-box-icon bg-info"><i class="fas fa-money-check-alt"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">Bank Balance</span>
              <span class="info-box-number">{{bank}}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
      <!-- </div> -->
      <!-- ./col -->
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-success">
          <div class="inner">
            <h4>Cash on Hand</h4>

            <p>{{cash}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-hand-holding-usd"></i>
          </div>
          
        </div> -->
      <!-- </div> -->
      <!-- ./col -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-hand-holding-usd"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Cash on Hand</span>
            <span class="info-box-number">{{cash}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>

      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-warning">
          <div class="inner">
            <h4>PDC Issued</h4>

            <p>{{pdcissued}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-check"></i>
          </div>
          
        </div> -->
      <!-- </div> -->
      <!-- ./col -->

      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-money-check"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">PDC Issued</span>
            <span class="info-box-number">{{pdcissued}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-danger">
          <div class="inner">
            <h4>PDC Received</h4>

            <p>{{pdcreceived}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-check"></i>
          </div>
          
        </div> -->
      <!-- </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-money-check"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">PDC Received</span>
            <span class="info-box-number">{{pdcreceived}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>

      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-info">
          <div class="inner">
            <h4>Total Payables</h4>

            <p>{{payables}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-bill-wave-alt"></i>
          </div>
          
        </div> -->
      <!-- </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-money-bill-wave-alt"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total Payables</span>
            <span class="info-box-number">{{payables}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>


      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-success">
          <div class="inner">
            <h4>Total Receivables</h4>

            <p>{{receivables}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-bill-wave-alt"></i>
          </div>
          
        </div>
      </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-money-bill-wave-alt"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total Receivables</span>
            <span class="info-box-number">{{receivables}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>


      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-warning">
          <div class="inner">
            <h4>Current Stock</h4>

            <p>{{stock}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-cubes"></i>
          </div>
          
        </div>
      </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-cubes"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Current Stock</span>
            <span class="info-box-number">{{stock}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>

      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-danger">
          <div class="inner">
            <h4>Total Expense</h4>

            <p>{{expense}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-money-bill"></i>
          </div>
          
        </div>
      </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-shopping-cart"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total Purchase</span>
            <span class="info-box-number">{{purchase}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-money-bill"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total Expense</span>
            <span class="info-box-number">{{expense}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>

      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-info">
          <div class="inner">
            <h4>Total Purchase</h4>

            <p>{{purchase}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-shopping-cart"></i>
          </div>
          
        </div>
      </div> -->
      

      <!-- <div class="col-lg-3 col-6"> -->
        <!-- small card -->
        <!-- <div class="small-box bg-success">
          <div class="inner">
            <h4>Total Sales</h4>

            <p>{{sales}}</p>
          </div>
          <div class="icon">
            <i class="fas fa-coins"></i>
          </div>
          
        </div>
      </div> -->
      <div class="col-md-3 col-sm-6 col-12">
        <div class="info-box">
          <span class="info-box-icon bg-info"><i class="fas fa-coins"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Total Sales</span>
            <span class="info-box-number">{{totalsales}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>

      

      <!-- ./col -->
    </div>
    <!-- /.row -->

    </div>