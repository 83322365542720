import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient) { }
  getDashboardData():Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'})
    return this.http.get<any>("http://admindashapi.eautomates.com/api/Common/getDashboard",{headers});
  }
  getTotalSales():Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'})
    return this.http.get<any>("http://admindashapi.eautomates.com/api/Common/getTotalSales",{headers});
  }
  getAccountsData():Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'})
    return this.http.get<any>("http://admindashapi.eautomates.com/api/Common/getAccountsData",{headers});
  }
  getSalesmanPerformance():Observable<any>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'})
    return this.http.get<any>("http://admindashapi.eautomates.com/api/Common/getSalesmanPerformance",{headers});
  }
}
