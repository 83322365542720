<h1>Welcome AboutUs</h1>
<div class="row" style="margin-left: 50px;">
    <div class="col-10">
      <!-- Custom Tabs -->
      <div class="card">
        <div class="card-header d-flex p-0">
          <h3 class="card-title p-3">Tabs</h3>
          <ul class="nav nav-pills ml-auto p-2">
            <li class="nav-item"><a class="nav-link active" href="#tab_1" data-toggle="tab">Tab 1</a></li>
            <li class="nav-item"><a class="nav-link" href="#tab_2" data-toggle="tab">Tab 2</a></li>
            <li class="nav-item"><a class="nav-link" href="#tab_3" data-toggle="tab">Tab 3</a></li>
           
          </ul>
        </div><!-- /.card-header -->
        <div class="card-body">
          <div class="tab-content">
            <div class="tab-pane active" id="tab_1">
                This is about page. Tab 1
             
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="tab_2">
                This is about page. Tab 2
            </div>
            <!-- /.tab-pane -->
            <div class="tab-pane" id="tab_3">
                This is about page. Tab 3
            </div>
            <!-- /.tab-pane -->
          </div>
          <!-- /.tab-content -->
        </div><!-- /.card-body -->
      </div>
      <!-- ./card -->
    </div>
    <!-- /.col -->
  </div>