import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  errormsg: string;
  constructor(private router: Router,private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
  }
  get f() { return this.loginForm.controls; }

    onSubmit() {
      this.submitted = true;
      if (this.loginForm.invalid) {
        return;
     }
     if(this.f.username.value=='admin@gmail.com' && this.f.password.value=='admin@123'){
      this.errormsg="";
      this.router.navigateByUrl('dashboard/home');
     }
     else{
       this.errormsg="invalid username or password";
     }
     
    }
  login() {
    
    this.router.navigateByUrl('dashboard/home');
  }

}
