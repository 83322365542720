<h1>Welcome Home</h1>
<section class="content">
    <div class="container-fluid">
      <!-- Info boxes -->
      <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon bg-info elevation-1"><i class="fas fa-coins"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">Today's Sales</span>
              <span class="info-box-number">
                {{dailysales}}
                
              </span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">Current Month Sales</span>
              <span class="info-box-number">{{monthlysales}}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">Current Year Sales</span>
              <span class="info-box-number">{{yearlysales}}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

            <div class="info-box-content">
              <span class="info-box-text">Last Month Sales</span>
              <span class="info-box-number">{{lastmonthsales}}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title"></h5>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                  <i class="fas fa-minus"></i>
                </button>
                <div class="btn-group">
                  <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                    <i class="fas fa-wrench"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" role="menu">
                    <a href="#" class="dropdown-item">Action</a>
                    <a href="#" class="dropdown-item">Another action</a>
                    <a href="#" class="dropdown-item">Something else here</a>
                    <a class="dropdown-divider"></a>
                    <a href="#" class="dropdown-item">Separated link</a>
                  </div>
                </div>
                <button type="button" class="btn btn-tool" data-card-widget="remove">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <div class="row">
                <div class="col-md-6" >
                  <p class="text-center">
                    <strong>Income-Expense</strong>
                  </p>
                  <!-- <canvas id="barchart1"  height="100" style="min-height: 100px;margin-top:25%" #barchart1></canvas> -->
                  <canvas id="barchart1"  height="130"  style="min-height: 100px;margin-top:25%" #barchart1></canvas>
                </div>
              
              
                <div class="col-md-6" >
                  <p class="text-center">
                    <strong>Salesman Performance</strong>
                  </p>
                  <canvas id="barchart"  height="180" style="height: 180px;" #barchart></canvas>
                </div>
                
               
                <!-- /.col -->
               
                <!-- /.col -->
              </div>
            
              <div class="row" style="margin-top: 60px;">
                <div class="col-md-6" >
                  <p class="text-center">
                    <strong>Datewise Salesman Sales</strong>
                  </p>

                  <div class="chart">
                    <!-- Sales Chart Canvas -->
                    <canvas id="salesChart" height="180" style="height: 180px;" hidden="true"></canvas>
                    <canvas id="myChart"  height="180" style="height: 180px;" #mychart></canvas>
                  </div>
                  <!-- /.chart-responsive -->
                </div>
                <div class="col-md-6" >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card bg-info">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-info"><i class="fas fa-money-check-alt"></i></span>
                          Bank Balance:{{bank}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-danger">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-danger"><i class="fas fa-hand-holding-usd"></i></span>
                          Cash on hand: {{cash}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <!-- ___________________ -->
                    <div class="col-md-6">
                      <div class="card bg-info">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-info"><i class="fas fa-money-check"></i></span>
                          PDC Issued:{{pdcissued}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-danger">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-danger"><i class="fas fa-money-check"></i></span>
                         PDC Received:{{pdcreceived}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <!-- _____________________ -->
                    <div class="col-md-6">
                      <div class="card bg-info">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-info"><i class="fas fa-money-bill-wave-alt"></i></span>
                          Total Payables:{{payables}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-danger">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-danger"><i class="fas fa-money-bill-wave-alt"></i></span>
                          Total Receivables:{{receivables}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <!-- ____________________ -->
                    <div class="col-md-6">
                      <div class="card bg-info">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-info"><i class="fas fa-cubes"></i></span>
                          Current Stock:{{stock}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-danger">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-danger"><i class="fas fa-shopping-cart"></i></span>
                          Total Purchase:{{purchase}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <!-- __________________________ -->
                    <div class="col-md-6">
                      <div class="card bg-info">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-info"><i class="fas fa-money-bill"></i></span>
                          Total Expense:{{expense}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-danger">
                        
                        <!-- /.card-header -->
                        <div class="card-body">
                          <span class="info-box-icon bg-danger"><i class="fas fa-coins"></i></span>
                          Total Sales:{{totalsales}}
                        </div>
                        <!-- /.card-body -->
                      </div>
                      <!-- /.card -->
                    </div>

                  </div>
                </div>
                
              </div>
              
              <!-- /.row -->
      