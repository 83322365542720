import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.sass']
})
export class AccountsComponent implements OnInit {
  receivables:any;
  payables:any;
  bank:any;
  cash:any;
  totalsales:any;
  purchase: any;
  stock: any;
  expense: any;
  pdcreceived: any;
  pdcissued: any;

  constructor(private dashservice: DashboardService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.getAccountsData();
  }
getAccountsData(){
  this.dashservice.getAccountsData().subscribe((data)=>{
    console.log(data)
    this.receivables=data[0].receivables;
    this.payables=data[0].payables;
    this.bank=data[0].bank;
    this.cash=data[0].cash;
    this.totalsales=data[0].totalsales;
    this.purchase=data[0].totalpurchase;
    this.stock=data[0].stock;
    this.expense=data[0].expense;
    this.pdcreceived=data[0].pdcreceived;
    this.pdcissued=data[0].pdcissued;
  })
}
}
