import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js'
import { Chart } from 'chart.js';
import { DashboardService } from '../dashboard.service';
export interface DashboardData {
  data: any[];
  label: string;
  borderColor: string;
  fill: boolean;
  backgroundColor: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  salesdata: any;
  totalsales=0;
  sales = [];
  salesman: any = [];
  date: any = [];
  linechart_data: ChartDataSets[] = [];
  count: number = 0;
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: string[] = [];
  canvas: any;
  ctx: any;
  dailysales: number;
  monthlysales: number;
  yearlysales: number;
  lastmonthsales: number;
  rgb = [];
  @ViewChild('mychart') mychart: any;

  performancedata: any;
  salesbar: any = [];
  salesmanbar: any = [];
  barchart_data: ChartDataSets[] = [];
  barcount: number = 0;
  barChartData: ChartDataSets[] = [];
  barChartLabels: string[] = [];



  @ViewChild('barchart') barchart: any;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
    
  };
  
  @ViewChild('barchart1') barchart1: any;
  receivables: any;
  payables: any;
  bank: any;
  cash: any;
  purchase: any;
  expense: any;
  stock: any;
  pdcissued: any;
  pdcreceived: any;

  constructor(private dashservice: DashboardService) {
   
   }

  ngOnInit(): void {
    
    
  }
  

  ngAfterViewInit() {
    this.buildsalesmangraph();
    this.getsalesdata();
    this.buildbarchart();
    this.buildgrossprofit()
    
  }
  buildsalesmangraph() {
    this.dashservice.getDashboardData().subscribe((data) => {
      this.salesdata = data;
      console .log(this.salesdata);
      for (let i = 0; i < this.salesdata.length; i++) {
        this.sales.push(this.salesdata[i].Sales);
        this.salesman.push(this.salesdata[i].SalesmanName);
        this.date.push(this.salesdata[i].Date);
      }

      this.count = countUnique(this.date);

      this.salesman = this.salesman.filter((value: any, index: any, categoryArray: any) => categoryArray.indexOf(value) === index);
      this.date = this.date.filter((value: any, index: any, categoryArray: any) => categoryArray.indexOf(value) === index);
      this.lineChartLabels = this.date;

      for (var i = 0; i < this.salesman.length; i++) {
        this.rgb.push("#" + Math.floor(Math.random() * 19777215).toString(16));
      }
      for (let i = 0; i < this.salesman.length; i++) {
        let temp: DashboardData = { data: [], label: "", borderColor: "", fill: true, backgroundColor: "" };
        temp.label = this.salesman[i];
        temp.borderColor = this.rgb[i];
        temp.backgroundColor = "";
        temp.fill = false;
        for (let j = 0; j < this.date.length; j++) {
          let flag = false;
          for (let k = 0; k < this.salesdata.length; k++) {
            if (this.salesdata[k].Date == this.date[j]) {
              if (this.salesdata[k].SalesmanName == this.salesman[i]) {
                flag = true;
                temp.data.push(this.salesdata[k].Sales);
                break;
              }
            }
          }
          if (flag == false) {
            temp.data.push(0);
          }
        }
        this.linechart_data[i] = temp;
      }
      this.lineChartData = this.linechart_data;
      this.canvas = this.mychart.nativeElement;
      this.ctx = this.canvas.getContext('2d');

      new Chart(this.ctx, {
        type: 'line',
        data: {
          datasets: this.lineChartData,
          labels: this.lineChartLabels
        },
        options: {
          responsive: true
        }
      });
      window.dispatchEvent(new Event('resize'));
    })

  }

  getsalesdata() {
    this.dashservice.getTotalSales().subscribe((data) => {
      console.log(data)
      this.lastmonthsales = data[0].totalsales;
      this.monthlysales = data[0].monthlysales;
      this.dailysales = data[0].dailysales;
      this.yearlysales = data[0].yearlysales;
    })
  }
  buildbarchart() {
    this.dashservice.getSalesmanPerformance().subscribe((data) => {
      console.log(data)
      this.performancedata = data;
      for (let i = 0; i < this.performancedata.length; i++) {
        this.salesmanbar[i] = this.performancedata[i].firstname
      }
      this.salesmanbar = this.salesmanbar.filter((value: any, index: any, categoryArray: any) => categoryArray.indexOf(value) === index);

      this.barChartLabels = this.salesmanbar;
      console.log("....")
      console.log(this.salesmanbar)

      let tempsalesdata: DashboardData = { data: [], label: "", borderColor: "", fill: true, backgroundColor: "" };
      tempsalesdata.label = "Sales";
      for (let i = 0; i < this.performancedata.length; i++) {
        tempsalesdata.data[i] = this.performancedata[i].monthlysales

      }
      tempsalesdata.borderColor = "#088F8F";
      tempsalesdata.backgroundColor ="#088F8F";
      tempsalesdata.fill = true;
      this.barchart_data[0] = tempsalesdata;
      console.log(tempsalesdata)
      let tempsalestarget: DashboardData = { data: [], label: "", borderColor: "", fill: true, backgroundColor: "" };
      tempsalestarget.label = "Target";
      for (let i = 0; i < this.performancedata.length; i++) {
        tempsalestarget.data[i] = this.performancedata[i].target

      }
      tempsalestarget.borderColor = "#8AFF8A";
      tempsalestarget.backgroundColor = "#8AFF8A";
      tempsalestarget.fill = true;
      this.barchart_data[1] = tempsalestarget;
      let tempsalesprofit: DashboardData = { data: [], label: "", borderColor: "", fill: true, backgroundColor: "" };
      tempsalesprofit.label = "Profit";
      for (let i = 0; i < this.performancedata.length; i++) {
        tempsalesprofit.data[i] = this.performancedata[i].profit

      }
      tempsalesprofit.borderColor = "#800000";
      tempsalesprofit.backgroundColor = "#800000";
      tempsalesprofit.fill = true;
      this.barchart_data[2] = tempsalesprofit;
      this.canvas = this.barchart.nativeElement;
     this.ctx = this.canvas.getContext('2d');
      new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: this.barchart_data,
        labels: this.salesmanbar
      },
      options: this.barChartOptions
    });
    })
    console.log("barchartdata")
    console.log(this.barchart_data)
    
  }

buildgrossprofit(){
  var expense:any;
  var income:any;
  var netprofit:any;
  this.dashservice.getAccountsData().subscribe((data)=>{
    this.receivables=data[0].receivables;
    this.payables=data[0].payables;
    this.bank=data[0].bank;
    this.cash=data[0].cash;
    this.totalsales=data[0].totalsales;
    this.purchase=data[0].totalpurchase;
    this.stock=data[0].stock;
    this.expense=data[0].expense;
    this.pdcreceived=data[0].pdcreceived;
    this.pdcissued=data[0].pdcissued;
    expense=data[0].expense;
    income=data[0].income;
    netprofit=income-expense;
    this.canvas = this.barchart1.nativeElement;
     this.ctx = this.canvas.getContext('2d');
      new Chart(this.ctx, {
      type: 'horizontalBar',
      data: {
        
        labels: ["Income","Expense","Net Profit"],
        datasets: [{
          barPercentage: 0.9,
          categoryPercentage: 1,
           data: [income,expense,netprofit],
           backgroundColor: ["#088F8F", "#800000", "#8AFF8A"], 
           barThickness:30,

           
        }]
     },
      options: {
        tooltips: {
          enabled: true
        },
        responsive: true,
        legend: {
           display: false,
           position: 'top',
           fullWidth: true,
           labels: {
             boxWidth: 10,
             padding: 10
           }
           
        },
        scales: {
          yAxes: [{
            
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false
            },
            ticks: {
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 14
            }
             
          }],
          xAxes: [{
              gridLines: {
                display: true,
                drawTicks: false,
                tickMarkLength: 5,
                drawBorder: false
              },
            ticks: {
              padding: 5,
              beginAtZero: true,
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11,
              callback: function(label:any, index, labels) {
               return label/1;
              }
                
            },
             scaleLabel: {
               display: true,
               padding: 10,
               fontFamily: 'Lato',
               fontColor: '#555759',
               fontSize: 16,
               
               labelString: ''
             },
            
          }]
       }
       
        
      }
      
    });
  })
}


}
function countUnique(iterable: any[]) {
  return new Set(iterable).size;
}
